import React, { useRef, useState, useEffect, PropsWithChildren, useContext } from "react";
import Loader from "../../Loader";
import Button from "../../Button";
import Modal from "../../Modal";
import Warning from "../../Warning";
import TooltipWithIcon from "../../TooltipWithIcon";

import clsx from "clsx";

import FigmaTestView from "../FigmaTestView";
import NodeInHtml from "../NodeInHtml";

import { IFormattedPrototype, getFormattedPrototype, isValidNavigateAction } from "../../../utils/figma";

import { fetchPrototype } from "../../../actions";
import { IconPlus, IconTrash } from "../../../icons";
import { IconCheck } from "../../../icons";
import { IconDelete } from "../../../icons";

import { getFigmaBlockGoalNode as getFigmaBlockGoalNodes } from "../../../utils/tests";
import { FigmaDisplayMode } from '../NodeInHtml/NodeInHtmlUtils';
import { FigmaBlock } from '../../../models/Test';
import { IPrototypeClickEvent } from '../../../models/Figma/IClickEvent';
import { useTranslation } from "react-i18next";
import { PrototypesEditorContext } from "../../TestEditor/contexts/PrototypesEditorContext";
import FigmaImport from "./Import/FigmaImport";
import FigmaIFrame from "../FigmaIFrame";
import TextDropdown from "../../TextDropdown";

import { initialContent } from "../../../helpers";
import { formatDate } from "../../../i18n/utils";
import i18n from "../../../i18n/config";
import useNativePrototypeData from '../hooks/useNativePrototypeData';
import _ from 'lodash';
import RadioSelect from "../../Common/RadioSelect";

const DEFAUL_PROTOTYPE_BOX_HEIGHT = 640;

interface IFigmaPrototypeCodeSetupProps {
  prototypeId?: string;
  goalNodeIds?: string[];
  blockId: string;
  updateTestBlock: (data: (block: any) => any, blockId: string) => void;
}

enum PrototypeLoadingStatus {
  idle = "idle",
  loading = "loading",
  error = "error",
}
export function FigmaPrototypeCodeSetup(props: IFigmaPrototypeCodeSetupProps) {
  const { updateTestBlock, blockId } = props;
  const prototypeData = useRef<IFormattedPrototype>({} as any);
  const { t } = useTranslation();

  const [prototypeStatus, setPrototypeStatus] = useState(PrototypeLoadingStatus.loading);
  const { setPrototypes } = useContext(PrototypesEditorContext);

  useEffect(() => {
    (async () => {
      const response = await fetchPrototype(props.prototypeId);

      if (response.ok) {
        prototypeData.current = getFormattedPrototype(await response.json());
        setPrototypeStatus(PrototypeLoadingStatus.idle);
        // Temporary state for all of the prototypes related to the test
        setPrototypes((current: Object) => {
          return {
            ...current,
            [blockId]: {
              ...prototypeData.current,
            },
          };
        });
        return;
      }
      setPrototypeStatus(PrototypeLoadingStatus.error);
    })();
  }, []);

  function addGoalNode(goalNodeId: string) {
    updateTestBlock((block) => {
      const newGoalNodeArray = getFigmaBlockGoalNodes(block) || [];
      return { ...block, goalNode: [...newGoalNodeArray, goalNodeId] };
    }, blockId);
  }

  function deleteGoalNode(goalNodeId: string) {
    updateTestBlock((block) => {
      const newGoalNodeArray = [...(getFigmaBlockGoalNodes(block) || [])];
      const index = newGoalNodeArray.indexOf(goalNodeId);
      if (index > -1) {
        newGoalNodeArray.splice(index, 1);
      }
      return { ...block, goalNode: newGoalNodeArray };
    }, blockId);
  }

  if (prototypeStatus === PrototypeLoadingStatus.loading) return <PrototypeBox message={t("The prototype is loading...")} />;
  if (prototypeStatus === PrototypeLoadingStatus.error) return <PrototypeBox message={t("Failed to load a prototype.")} />;
  if (prototypeStatus === PrototypeLoadingStatus.idle) return <>
    <div className="flex items-center">
      <div className="rounded-lg object-contain mt-4">
        <PrototypePreview width={120} prototype={prototypeData.current} />
      </div>
      <div className="ml-6 header3">{prototypeData.current?.name || ""}</div>
    </div>
    <GoalScreens
      goalNodeIds={props.goalNodeIds}
      prototype={prototypeData.current}
      addGoalNode={addGoalNode}
      deleteGoalNode={deleteGoalNode} />
  </>;
  return null;
}

function PrototypeBox(props: { message: string; } & PropsWithChildren) {
  return (
    <div className="relative rounded-2xl bg-black my-2" style={{ height: DEFAUL_PROTOTYPE_BOX_HEIGHT }}>
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-white font-medium text-center">
          <Loader />
          <div className="mt-4 text-base font-normal text-gray-500">{props.message}</div>
        </div>
      </div>
      <div className="relative z-10">{props.children}</div>
    </div>
  );
}


interface IGoalScreenProps {
  goalNodeIds?: string[];
  isGoalStatusLoading?: boolean;
  prototype: IFormattedPrototype;
  addGoalNode: (goalNodeId: string) => void;
  deleteGoalNode: (goalNodeId: string) => void;
}
function GoalScreens(props: IGoalScreenProps) {
  const { t } = useTranslation();
  const [isGoalScreenSelectModalOpen, setIsGoalScreenSelectModalOpen] = useState(false);
  const [goalPickerActiveNode, setGoalPickerActiveNode] = useState(props.prototype.startNodeId);
  const hasGoalNodes = !!props.goalNodeIds?.length;
  const canAddGoalNode = goalPickerActiveNode && goalPickerActiveNode !== props.prototype.startNodeId && !props.goalNodeIds?.includes(goalPickerActiveNode);

  function selectGoalNode() {
    if (canAddGoalNode) {
      props.addGoalNode(goalPickerActiveNode);
      setIsGoalScreenSelectModalOpen(false);
    }
  }

  return (
    <>
      <div className="my-4">
        <div className="flex items-center caption">
          <span className="block">{t("Goal screens")}</span>
          <TooltipWithIcon text={t("When the respondent reaches one of these screens in your prototype, the task is considered successfully completed")} className={"ml-2 text-gray-600"} />
        </div>
        {hasGoalNodes && (
          <div className="flex gap-x-6 items-center flex-wrap my-3">
            {props.goalNodeIds?.map((goalNodeId, i) => {
              return (
                <div className="goal-node-preview flex flex-col my-2" key={goalNodeId}>
                  <div className="goal-node-preview__container goalNode rounded-lg relative" style={{ width: 120, height: 120 }} >
                    <IconDelete
                      className="absolute fill-current text-white cursor-pointer z-999 icon-delete hover:opacity-50"
                      onClick={() => props.deleteGoalNode(goalNodeId)} />

                    <div className="goalNodeImage">
                      {props.isGoalStatusLoading ? <Loader /> : <PrototypePreview prototype={props.prototype} nodeId={goalNodeId} />}
                    </div>
                  </div>

                  <span className="goal-node-preview__title mt-4">
                    {i + 1}. {props.prototype?.nodesForHtml[goalNodeId]?.name}
                  </span>
                </div>
              );
            })}
          </div>
        )}

        <Button className="add-goal-node-button mt-2" type="secondary" name={t("Add")}
          handler={() => setIsGoalScreenSelectModalOpen(true)}
          icon={<IconPlus width={24} height={24} className="mr-1" />} />
      </div>

      <Modal isOpen={isGoalScreenSelectModalOpen} setIsOpen={setIsGoalScreenSelectModalOpen} width="90%" background>
        <div className="px-5 py-2">
          <span className="block my-2 text-xl font-bold font-display">{t("Select a goal screen")}</span>
          <GoalScreenSelector prototype={props.prototype} onScreenChanged={setGoalPickerActiveNode} />
          {!canAddGoalNode && (
            <Warning
              header={t("This screen can't be selected as a Goal Screen")}
              text={t("Either this screen is your prototype starting screen or has already selected as a Goal Screen. Please, navigate to another screen in your prototype.")}
              className="my-4" />
          )}
          <Button
            className="my-4"
            type="primary"
            name={t("Select this screen as a goal screen")}
            handler={selectGoalNode}
            icon={<IconCheck width={24} height={24} className="mr-1 fill-current text-white" />}
            disabled={!canAddGoalNode}
          ></Button>
        </div>
      </Modal>
    </>
  );
}


function PrototypePreview(props: { prototype: IFormattedPrototype; nodeId?: string; width?: number; }) {
  const { width = 120, prototype, nodeId = null } = props;

  const selectedNodeId = nodeId || prototype.startNodeId;
  const node = prototype.nodesForHtml[selectedNodeId];

  const margin = Math.round(width * 0.1);
  const scaleRate = (width - margin * 2) / node.width;

  return (
    <div className="rounded-lg mr-2 shadow-sm overflow-hidden drop-shadow relative bg-black" style={{ width: width, height: width }}>
      <div
        className="rounded-lg"
        style={{
          width: node.width,
          transformOrigin: "0 0",
          transform: `scale(${scaleRate})`,
          marginTop: node.height * scaleRate < width ? Math.round((width - node.height * scaleRate) / 2) : margin,
          marginLeft: margin,
          overflow: "hidden",
        }}
      >
        <NodeInHtml
          display={FigmaDisplayMode.actualSize}
          imageScale={prototype.settings.imageScale}
          node={node}
          prototype={prototype} />
      </div>
    </div>
  );
}

function GoalScreenSelector(props: { prototype: IFormattedPrototype; onScreenChanged: (nodeId: string) => void; }) {
  const [isLoading, setIsLoading] = useState(true);

  function onPrototypeClick(data: IPrototypeClickEvent) {
    if (isValidNavigateAction(data.action) && data.action.destinationId) {
      props.onScreenChanged(data.action.destinationId);
    }
  }

  return (
    <div className="relative rounded-xl bg-black overflow-hidden shadow-sm drop-shadow">
      <FigmaTestView
        style={{ height: DEFAUL_PROTOTYPE_BOX_HEIGHT }}
        prototype={props.prototype}
        startNodeId={props.prototype.startNodeId}
        onClick={onPrototypeClick}
        onLoad={() => {
          setIsLoading(false);
          props.onScreenChanged(props.prototype.startNodeId);
        }} />
      {isLoading && <Loader className="rounded-xl bg-black z-99999 absolute inset-0" />}
    </div>
  );
}

/* Figma Prototype link setup components here */

interface IFigmaPrototypeLinkSetupProps {
  block: FigmaBlock;
  blockId: string;
  updateTestBlock: (data: (block: any) => any, blockId: string) => void;
}

export function FigmaPrototypeLinkSetup(props: IFigmaPrototypeLinkSetupProps) {
  const { t } = useTranslation();
  const { blockId, scaling } = props.block;
  const [figmaBlock, setFigmaBlock] = useState<FigmaBlock>(_.cloneDeep(props.block));
  const updateTestBlock = props.updateTestBlock;
  const importedDate = figmaBlock.importedAt?.seconds && formatDate(new Date(figmaBlock.importedAt?.seconds * 1000), i18n.language);

  const { prototypeData } = useNativePrototypeData(figmaBlock.prototypeDataUrl);

  useEffect(() => {
    if (prototypeData.data && props.block) {
      setFigmaBlock(Object.assign(_.cloneDeep(props.block), prototypeData.data));
    } else {
      setFigmaBlock(props.block);
    }
  }, [props.block]);

  useEffect(() => {
    if (prototypeData.data) {
      setFigmaBlock(current => {
        const a = Object.assign(_.cloneDeep(current), prototypeData.data);
        return a;
      });
    }
  }, [prototypeData.data]);

  if (!props.block.fileId || !props.block.fileVersion) {
    return null;
  }

  function addGoalNode(goalNodeId: string) {
    updateTestBlock((block) => {
      const newGoalNodeArray = getFigmaBlockGoalNodes(block) || [];
      return { ...block, goalNode: [...newGoalNodeArray, goalNodeId] };
    }, blockId);
  }

  function deleteGoalNode(goalNodeId: string) {
    updateTestBlock((block) => {
      const newGoalNodeArray = [...(getFigmaBlockGoalNodes(block) || [])];
      const index = newGoalNodeArray.indexOf(goalNodeId);
      if (index > -1) {
        newGoalNodeArray.splice(index, 1);
      }
      return { ...block, goalNode: newGoalNodeArray };
    }, blockId);
  }

  function onFitContentChange(value: string) {
    updateTestBlock((block) => {
      return { ...block, scaling: value };
    }, blockId);
  }

  function deletePrototype() {
    updateTestBlock((block) => {
      const { text, questionHtml, withVideo, showDefaultCursor, blockId, createdAt } = block;
      return { ...initialContent().figma, text, questionHtml, withVideo, showDefaultCursor, scaling, blockId, createdAt };
    }, blockId);
  }

  if (figmaBlock.startNodeId) {
    return (
      <div className="figma-prototype-setup__wrapper flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <span className="captionNoMargin">{t("Prototype")}</span>
          <div className="w-full max-w-full fill-white flex space-x-4 items-center group relative border-1 border-gray-200 rounded-xl p-3">
            <img
              src={figmaBlock.nodeImages[figmaBlock.startNodeId]}
              className="w-12 h-12 rounded-md block object-cover object-top shadow-xs"
            />
            <div className="flex-1 overflow-hidden">
              <div className="max-w-full truncate captionLarge">{figmaBlock.flowName}</div>
              <div className="text-gray-600 text-xs truncate max-w-full w-full overflow-hidden">{t("Imported at:")} {importedDate}</div>
            </div>
            <IconTrash className="w-5 h-5 fill-current text-gray-700 hover:text-red-600 cursor-pointer invisible group-hover:visible"
              onClick={deletePrototype} />
          </div>
        </div>
        <div className="figma-prototype-setup__scale-wrapper flex flex-col gap-3">
          <span className="captionNoMargin">{t("Prototype display")}</span>
          <RadioSelect
            name="prototype-display"
            value={figmaBlock.scaling}
            options={{
              "scale-down-width": {
                label: t("Fit width"),
              },
              "scale-down": {
                label: t("Fit to screen"),
              }
            }}
            onChange={onFitContentChange}
            columns={2}
          />
          <span className="text-gray-600 text-xs">
            {t("Default scaling fits width. Use Fit to screen to avoid vertical scrolling.")}
          </span>
        </div>

        <FigmaLinkGoalScreens
          block={figmaBlock}
          blockId={figmaBlock.blockId}
          fileId={figmaBlock.fileId!}
          fileVersion={figmaBlock.fileVersion}
          goalNodeIds={figmaBlock.goalNode}
          addGoalNode={addGoalNode}
          deleteGoalNode={deleteGoalNode}
          startNodeId={figmaBlock.startNodeId}
          nodeImages={figmaBlock.nodeImages}
          scaling={figmaBlock.scaling}
        />
      </div>
    );
  }

  return null;
}

interface IFigmaLinkGoalScreenProps {
  block: FigmaBlock;
  blockId: string;
  fileId: string;
  fileVersion: string;
  goalNodeIds?: string[];
  addGoalNode: (goalNodeId: string) => void;
  deleteGoalNode: (goalNodeId: string) => void;
  startNodeId: string;
  nodeImages: { [key: string]: string };
  scaling?: "scale-down" | "scale-down-width";
}

function FigmaLinkGoalScreens(props: IFigmaLinkGoalScreenProps) {
  const { t } = useTranslation();
  const [isGoalScreenSelectModalOpen, setIsGoalScreenSelectModalOpen] = useState(false);
  const [goalPickerActiveNode, setGoalPickerActiveNode] = useState(props.startNodeId);
  const hasGoalNodes = !!props.goalNodeIds?.length;
  const canAddGoalNode = goalPickerActiveNode && goalPickerActiveNode !== props.startNodeId && !props.goalNodeIds?.includes(goalPickerActiveNode);

  function selectGoalNode() {
    if (canAddGoalNode) {
      props.addGoalNode(goalPickerActiveNode);
      setIsGoalScreenSelectModalOpen(false);
    }
  }

  return (
    <>
      <div className="figma-prototype-setup__goal-screens-wrapper flex flex-col">
        <div className="flex items-center caption">
          <span className="block">{t("Goal screens")}</span>
        </div>
        {hasGoalNodes && (
          <div className="flex -space-x-2 hover:space-x-4 transition-all duration-150 ease-in-out items-center flex-wrap my-3 group/goal-screens">
            {props.goalNodeIds?.map((goalNodeId, i, goalNodeIds) => {
              return (
                <div
                  className={clsx(
                    "goal-node-preview flex flex-col group-hover/goal-screens:rotate-0 transition-all duration-150 ease-in-out",
                    goalNodeIds.length > 1 && (i % 2 === 0 ? "rotate-[-10deg]" : "rotate-[10deg]")
                  )}
                  key={goalNodeId}
                >
                  <div className="goal-node-preview__container goalNode relative w-12 h-12">
                    <IconTrash
                      className="absolute fill-current text-white cursor-pointer z-999 icon-delete hover:opacity-50"
                      onClick={() => props.deleteGoalNode(goalNodeId)}
                    />
                    <div className="goalNodeImage">
                      <img
                        src={props.nodeImages[goalNodeId] || undefined}
                        className="w-12 h-12 mr-4 block object-cover object-top rounded-md shadow-lg border-2 border-white"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <span className="text-gray-600 text-xs mt-2">{t(
          "When the respondent reaches one of these screens in your prototype, the task is considered successfully completed"
        )}</span>
        <Button
          className="add-goal-node-button mt-2"
          type="dashed"
          handler={() => setIsGoalScreenSelectModalOpen(true)}
          icon={<IconPlus width={20} height={20} className="fill-current" />}
        />
      </div>

      <Modal isOpen={isGoalScreenSelectModalOpen} setIsOpen={setIsGoalScreenSelectModalOpen} width="800px" background>
        <div className="px-5 py-2">
          <span className="block my-2 text-xl font-bold font-display">{t("Select a goal screen")}</span>
          <div className="relative rounded-xl overflow-hidden">
            <FigmaIFrame
              options={{
                fileId: props.fileId,
                startNodeId: props.startNodeId,
                fileVersion: props.fileVersion,
                scaling: props.scaling || "scale-down",
                bg: "EDF1F5"
              }}
              width={'100%'}
              height={600}
              onPresentedNodeChanged={(data) => {
                if (data.presentedNodeId) {
                  setGoalPickerActiveNode(data.presentedNodeId);
                }
              }}
              onClick={(data) => {
                console.log(data);
              }}
            />
          </div>
          {!canAddGoalNode && (
            <Warning
              header={t("This screen can't be selected as a Goal Screen")}
              text={t(
                "Either this screen is your prototype starting screen or has already selected as a Goal Screen. Please, navigate to another screen in your prototype."
              )}
              className="my-4"
            />
          )}
          <Button
            className="my-4"
            type="primary"
            name={t("Select this screen as a goal screen")}
            handler={selectGoalNode}
            icon={<IconCheck width={24} height={24} className="mr-1 fill-current text-white" />}
            disabled={!canAddGoalNode}
          ></Button>
        </div>
      </Modal>
    </>
  );
}