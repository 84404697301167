import { useState, useContext, useEffect } from "react";
import { makeRequest } from "../../../http/Client";
import { IReportTestData } from "../../../models/Report";
import { useSendNotification } from "../../../hooks";

import { TestsActions } from "../../../actions/testsActions";

import appContext from "../../../AppContext";

import _, { findIndex } from "lodash";
import { ITestAnswer } from "../../../models/Report/ITestAnswer";
import { getAnswersByBlocksWithMetadata, patchTestWithScreeningQuestions } from "../utils";
import { getPanelStatusBaseCondition } from "../Filters/Filters";
import { PanalAnswerStatuses } from "../../../models/Report";
import { getTestFigmaBlockData } from "../FigmaReport/useFigmaBlockData";
import { ITest } from "../../../models/Test";

export const useReport = ({
  testId,
  sharingToken,
  setFilter,
}: {
  testId: string;
  sharingToken?: string | undefined | null;
  setFilter: any;
}) => {
  const [isReportTestDataLoading, setIsReportTestDataLoading] = useState(true);
  const [isHugeReport, setIsHugeReport] = useState(false);
  const [reportTestData, setReportTestData] = useState<IReportTestData | null | undefined>(null);
  const { state, dispatch } = useContext(appContext);
  const [testData, setTestData] = useState<ITest | null>(null);
  const setNotification = useSendNotification();

  useEffect(() => {
    const fetchTestFigmaData = async () => {
      let test;
      let updatedTestContent;

      if (sharingToken && !state.tests[testId]) {
        test = await getTestWithSharingToken(testId, sharingToken);
      } else if (state.tests[testId] && !sharingToken) {
        test = state.tests[testId];
      }

      if (test) {
        const updatedPublishedContent = await getTestFigmaBlockData(test.publishedContent);
        const updatedTest = { ...test, publishedContent: updatedPublishedContent };
        setTestData(updatedTest);
      }
    };

    fetchTestFigmaData();
  }, [testId, sharingToken, state.tests]);

  const handleError = (e: Error) => {
    if (e instanceof DOMException && e.name === "AbortError") return;
    setNotification("error", "Something went wrong while loading your report");
  };

  const getTestWithSharingToken = async (testId: string, sharingToken: string) => {
    try {
      const result = await TestsActions.getTestWithSharingToken(testId, sharingToken);
      dispatch(result);
      return result.payload.data;
    } catch (error) {
      handleError(error as Error);
    }
  };

  const addPanelReportFilter = (reportTestData: IReportTestData | undefined | null, setFilter: any) => {
    if (reportTestData) {
      const isNonCompletedPanelStatusPresent = Object.keys(reportTestData.answersMetaData).some((answerId) =>
        reportTestData.answersMetaData[answerId].panelRespondentStatus && reportTestData.answersMetaData[answerId].panelRespondentStatus !== PanalAnswerStatuses.completed
      );

      if (isNonCompletedPanelStatusPresent) {
        const panelStatusBaseCondition = getPanelStatusBaseCondition();
        setFilter((filter: any) => {
          const newFilter = _.cloneDeep(filter) as any;
          newFilter.conditions[panelStatusBaseCondition.id] = panelStatusBaseCondition;
          return newFilter;
        });
      }
    }
  }

  const deleteAnswer = async (answerId: string) => {

    await fetch(`/api/tests/${testId}/answers/${answerId}/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    setReportTestData((current) => {
      if (!current) return current;
      const newReportTestData = _.cloneDeep(current);
      Object.keys(newReportTestData.answers).forEach((blockId) => {
        newReportTestData.answers[blockId].responses = newReportTestData.answers[blockId].responses.filter((answer) => answer.answerId !== answerId);
      });
      return newReportTestData;
    });
  };


  const getAnswers = async (testId: string, sharingToken: string, abortSignal?: AbortSignal) => {
    const answers: ITestAnswer[] = [];
    let hasNextPage = false;
    let cursor: string | number | undefined = undefined;

    do {
      const batch = await fetchAnswersBatch(testId, sharingToken, cursor);
      hasNextPage = batch.hasNextPage;
      answers.push(...batch.answers);
      cursor = _.last(answers)?.createdAt;
      if (hasNextPage && !isHugeReport) {
        setIsHugeReport(true);
      }
    } while (!abortSignal?.aborted && hasNextPage)

    return getAnswersByBlocksWithMetadata(answers);
  };

  async function fetchAnswersBatch(testId: string, sharingToken: string, lastCreatedAt?: string | number | undefined, abortSignal?: AbortSignal) {
    let apiUrl = `/api/v1/tests/${testId}/answers?sharingToken=${sharingToken}`;
    if (lastCreatedAt) {
      apiUrl += `&lastCreatedAt=${new Date(lastCreatedAt).toISOString()}`;
    }
    const answersBatchResponse = await makeRequest(apiUrl, 'GET', undefined, abortSignal);
    const answersBatch: {
      answers: ITestAnswer[];
      hasNextPage: boolean;
    } = await answersBatchResponse.json();
    return answersBatch;
  }

  const getTestWithAnswers = async (test: ITest, testId: string, sharingToken?: string | undefined | null, abortSignal?: AbortSignal) => {
    /* 1. Getting answers */

    const answersWithMetaData = await getAnswers(testId, sharingToken || _.get(test, "sharingToken"), abortSignal);

    /* 2. Patching test data with screening questions (if applicable). Returning united data  */
    return {
      ...patchTestWithScreeningQuestions(test),
      answersMetaData: answersWithMetaData.answersMetaData,
      answers: answersWithMetaData.answers,
    };
  };

  useEffect(() => {
    if(!testData) return;
    const abortController = new AbortController();

    getTestWithAnswers(testData, testId, sharingToken, abortController.signal)
      .then((testWithAnswers) => {
        setReportTestData(testWithAnswers as any as IReportTestData);

        /* Setting Filter if report has custom panel responses */
        addPanelReportFilter(testWithAnswers as any as IReportTestData, setFilter);

        setIsReportTestDataLoading(false);
      })
      .catch(handleError);

    return () => abortController.abort();
  }, [testData]);

  return {
    isHugeReport,
    reportTestData,
    isReportTestDataLoading,
    deleteAnswer,
  };
};
